<template>
  <div class="flex flex-col w-full h-full p-5">
    <div
      v-if="isDownloading"
      class="absolute left-0 top-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-80"
    >
      <button class=" btn btn-lg btn-circle btn-ghost loading" />
    </div>
    <div
      id="p_report_div"

      :class="{'flex-auto': !printMode, 'w-full h-full': printMode}"
    >
      <start-page
        v-if="page === 1 && !printMode"
        v-model="selectedCandidate"
        :candidates="candidates"
        :campaign-id="id"
        :print-mode="printMode"
        :value="selectedCandidate"
      />
      <title-page
        v-if="printMode || page === 2"
        :show-no-results-msg="showNoResultsMsg"
        :battery-id="id"
      />
      <intro-page v-if="(printMode && loadData )|| page === 3" />
      <intro-page3 v-if="(printMode && loadData ) || page === 4" />
      <select-candidate
        v-model="selectedCandidate"
        :candidates="candidates"
        :summary-data="summaryData"
        v-if="printMode || page === 5"
        :print-mode="printMode"
      />
      <spider-page
        v-if="printMode || page === 6"
        key="bfi10"
        :options="$options.chartOptions"
        :content="$options.content.bfi10"
        :dataset="dataset_bfi10"
        :candidate="selectedCandidate"
        :summary-data="summaryData.bfi10"
        :print-mode="printMode"
      />
      <spider-page
        v-if="printMode || page === 7"
        key="iri"
        :options="$options.chartOptions"
        :content="$options.content.iri"
        :dataset="dataset_iri"
        :candidate="selectedCandidate"
        :summary-data="summaryData.iri"
        :print-mode="printMode"
      />
      <curve-page
        v-if="printMode || page === 8"
        :dataset="dataset_asrs"
        :candidate="selectedCandidate"
        key="asrs"
        :content="$options.content.asrs"
        :print-mode="printMode"
      />
      <curve-page
        v-if="printMode || page === 9"
        :dataset="dataset_aq"
        :candidate="selectedCandidate"
        key="aq"
        :content="$options.content.aq"
        :print-mode="printMode"
      />
      <spider-page
        v-if="printMode || page === 10"
        key="aesc"
        :options="$options.aescChartSettings"
        :content="$options.content.aesc"
        :dataset="dataset_aesc"
        :candidate="selectedCandidate"
        :summary-data="summaryData.aesc"
        :print-mode="printMode"
        :is-regulation-page="true"
      />
    </div>
    <bottom-menu
      v-if="!printMode && !showNoResultsMsg"
      v-model="page"
      :max="10"
      :campaign-id="(!!$store.getters['ANALYTICS/getCurrentCampaign']) ? $store.getters['ANALYTICS/getCurrentCampaign'].id : ''"
      :is-downloading="isDownloading"
      @download="downloadReport"
      :personality="true"
      :has-selected-candidate="results.length > 0 && selectedCandidate !== null"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import content from '@/content/questionnaireReportcontent.json'
import { spiderOptions, normStyling, candidateStyling } from '@/utils/chartUtils'
import SelectCandidate from '../components/SelectCandidate.vue'
import SpiderPage from '../components/SpiderPage.vue'
import CurvePage from '../components/CurvePage.vue'
import StartPage from '../components/StartPage.vue'
import TitlePage from '../components/TitlePage.vue'
import IntroPage from '../components/IntroPage.vue'
import IntroPage3 from '../components/IntroPage3.vue'
import BottomMenu from '@/components/reports/BottomMenu.vue'
import download from 'downloadjs'

const stanineMap = {
  '-10': '',
  '-1': 1,
  7: 2,
  16: 3,
  31: 4,
  49.5: 5,
  68: 6,
  82.5: 7,
  92: 8,
  100: 9,
  108: 10
}
const stanineMapReversed = {
  0: -30,
  1: -1,
  2: 7,
  3: 16,
  4: 31,
  5: 49.5,
  6: 68,
  7: 82.5,
  8: 92,
  9: 100,
  10: 108
}

const aescChartSettings = JSON.parse(JSON.stringify(spiderOptions))

spiderOptions.plugins.tooltip = {
  callbacks: {
    label: (ctx) => {
      if (stanineMap[ctx.parsed.r]) {
        return `${ctx.dataset.label}: ${stanineMap[ctx.parsed.r]}`
      }
      return `${ctx.dataset.label}: 0`
    }
  }
}

spiderOptions.scales.r = {
  ...spiderOptions.scales.r,
  max: 100,
  min: -30,
  ticks: {
    showLabelBackdrop: false,
    padding: 0,
    stepSize: 0.5,
    z: 1,
    callback (tickValue) {
      return stanineMap[tickValue.toString()]
    },
    font: {
      size: 10
    }
  },
  grid: {
    lineWidth: 2
  }
}

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    pdf: {
      type: Boolean,
      required: false,
      default: false
    },
    candidateId: {
      type: String,
      required: false,
      default: null
    }
  },
  components: {
    SelectCandidate,
    SpiderPage,
    CurvePage,
    StartPage,
    TitlePage,
    IntroPage,
    IntroPage3,
    BottomMenu
  },
  // $options
  content,
  chartOptions: spiderOptions,
  aescChartSettings,

  data () {
    return {
      results: [],
      candidates: [],
      selectedCandidate: null,
      showNoResultsMsg: false,
      page: 1,
      printMode: this.pdf,
      showCharts: false,
      isDownloading: false,
      loadData: false,
      rename: null,
      campaignId: this.$route.query.campaignId
    }
  },
  computed: {
    ...mapGetters({
      currentCampaign: 'ANALYTICS/getCurrentCampaign'
    }),
    summaryData () {
      const [bfi10MaxMeasures, bfi10MinMeasures] = this.getMaxMin('BFI10', ['ev', 'an', 'cn', 'nt', 'op'])

      const [iriMaxMeasures, iriMinMeasures] = this.getMaxMin('IRI', ['pt', 'pd', 'ec'])

      const [aescMaxMeasures, aescMinMeasures] = this.getMaxMin('GENERAL_AESC', ['ga', 'er', 'ev', 'iv'])

      return {
        bfi10: {
          min: bfi10MinMeasures,
          max: bfi10MaxMeasures
        },
        iri: {
          min: iriMinMeasures,
          max: iriMaxMeasures
        },
        aesc: {
          min: aescMinMeasures,
          max: aescMaxMeasures
        },
        aq: {
          score: this.results.filter(el => el.type === 'AQ').length > 0 ? this.results.filter(el => el.type === 'AQ')[0].result.main : 0
        },
        asrs: {
          score: this.results.filter(el => el.type === 'ASRS').length > 0 ? this.results.filter(el => el.type === 'ASRS')[0].result.main : 0
        }
      }
    },
    dataset_bfi10 () {
      const datas = this.results.filter(el => el.type === 'BFI10')[0].result
      return {
        labels: [
          'Extraversion',
          'Agreeableness',
          'Conscientiousness',
          'Neuroticism',
          'Openness'
        ],
        datasets: [{
          label: 'Candidate',
          data: [datas.ev, datas.an, datas.cn, datas.nt, datas.op].map(val => stanineMapReversed[val]),
          ...candidateStyling
        }, {
          label: 'Norm',
          data: [5, 5, 5, 5, 5].map(val => stanineMapReversed[val]),
          ...normStyling
        }]
      }
    },
    dataset_iri () {
      const datas = this.results.filter(el => el.type === 'IRI')[0].result
      return {
        labels: [
          'Perspective-taking',
          'Personal distress',
          'Empathic concern'
        ],
        datasets: [{
          label: 'Candidate',
          data: [datas.pt, datas.pd, datas.ec].map(val => stanineMapReversed[val]),
          ...candidateStyling
        }, {
          label: 'Norm',
          data: [5, 5, 5].map(val => stanineMapReversed[val]),
          ...normStyling
        }]
      }
    },
    dataset_asrs () {
      const datas = this.results.filter(el => el.type === 'ASRS')[0].result
      // maybe we should flip the scores in the backend instead?
      // return [datas.main, datas.ia, datas.ha].map(score => 10 - score)
      return [datas.main, 10 - datas.ia, datas.ha]
    },
    dataset_aq () {
      const datas = this.results.filter(el => el.type === 'AQ')[0].result
      return [datas.main, datas.fd, datas.sa]
    },
    dataset_aesc () {
      const datas = this.results.filter(el => el.type === 'GENERAL_AESC')[0].result
      return {
        labels: [
          'Ability to focus',
          'Emotional regulation',
          'Being social',
          'Cost of being social'
        ],
        datasets: [
          {
            label: 'Candidate',
            data: [datas.ga, datas.er, datas.ev, datas.iv],
            ...candidateStyling
          }]
      }
    }

  },
  methods: {
    getMaxMin (questionnaireKey, measureKeys) {
      const results = this.results.filter(el => el.type === questionnaireKey)[0].result
      const values = measureKeys.map(key => results[key])
      const max = Math.max(...values)
      const min = Math.min(...values)
      const maxMeasures = Object.keys(results).filter((el) => results[el] === max)
      const minMeasures = Object.keys(results).filter((el) => results[el] === min)

      return [maxMeasures, minMeasures]
    },
    async getResults (id, campaignId) {
      try {
        this.loadData = false
        this.results = await this.$api.getQuestionnaireResults(id, campaignId)
        setTimeout(() => { this.showCharts = true }, 500)
        this.loadData = true
        await this.$store.dispatch('USER/updateSubscriptionStatus')
      } catch (error) {
        console.log(error.response.status)
      }
    },
    async getCandidates (id) {
      try {
        this.candidates = await this.$api.getQuestionnaireCandidates(id)
        if (this.candidateId) {
          this.selectedCandidate = this.candidates.find(candidate => candidate.user_id === this.candidateId)
        }
      } catch (error) {
        console.log(error.response.status)
        if (error.response.status === 404) {
          this.showNoResultsMsg = true
        }
      }
    },
    async downloadReport (anonymized) {
      this.isDownloading = true
      const { data, headers } = await this.$api.getPdfReportPersonality(this.id, anonymized, this.selectedCandidate.user_id, this.campaignId)
      const contentType = headers['content-type']
      this.rename = this.selectedCandidate.name
      if (anonymized) {
        this.rename = this.selectedCandidate.alt_id
      } else {
        if (this.selectedCandidate.name !== null) {
          this.rename = this.selectedCandidate.name
        } else {
          this.rename = this.selectedCandidate.email.replace('.', '_')
        }
      }
      download(data, `Personality report ${this.rename}`, contentType)

      this.isDownloading = false
      this.printMode = false
    }
  },
  watch: {
    async selectedCandidate (candidate) {
      if (this.campaignId !== null) {
        await this.getResults(candidate.user_id, this.campaignId)
      }
    },
    page () {
      if (this.page < 1) this.page = 1
      if (this.page > 10) this.page = 10
    }
  },
  async mounted () {
    await this.getCandidates(this.id)

    if (this.pdf) {
      await this.getResults(this.candidateId, this.campaignId)
    }
  }
}
</script>
<style lang="scss">@import '../personality_report.scss';
</style>
