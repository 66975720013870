<template>
  <div
    :class="{'flex flex-col h-full': !printMode, 'w-full h-full print-page-size-top-margin': printMode}"
  >
    <div class="flex items-center justify-between">
      <div class="flex page-title">
        <h2>
          {{ content.title }}
        </h2>
        <img
          class="mx-2 mt-2 transition duration-150 ease-in-out img-fluid info-icon"
          src="@/assets/reports/personality/Circle_Info.svg"
          data-bs-toggle="tooltip"
          title="The strongest abilities comes natural and easy and are often dominating."
        >
      </div>
      <div
        class="flex float-right"
        v-if="!printMode"
      >
        <img
          class="mx-1 img-fluid profile-icon"
          src="@/assets/reports/personality/User_cicrle_duotone_line.svg"
        >
        <p class="text-base font-medium">
          {{ candidate.name ? candidate.name : candidate.email }}
        </p>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4 mb-6">
      <div class="summary-box">
        <p>
          <strong>Summary</strong>
        </p>
        <p>
          {{ summaryBase }} <span
            v-for="trait in summaryData.max"
            :key="trait"
          >
            {{ content.measures[trait].label }},  {{ content.measures[trait].summary.toLowerCase() }}
          </span>
        </p>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-4 mt-6">
      <div class="col-span-2">
        <div
          class="w-full spiderdiv"
        >
          <radar-chart
            :width="575"
            :height="575"
            :options="options"
            :chart-data="dataset"
          />
        </div>
      </div>
      <div>
        <ul
          class="list-none list-outside"
          v-for="(traitContent,index) in Object.values(content.measures)"
          :key="index"
        >
          <li class="mb-10">
            <h5 class="text-base font-semibold">
              {{ traitContent.label }}
            </h5>
            <p class="mt-4 text-base font-normal leading-snug">
              {{ dataset.datasets[0].data[traitContent.dataIndex] >= getNormValue ? traitContent.descHigher : traitContent.descLower }}
              <!-- const stanineMap = {..., 49.5: 5, ...} -->
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import { summaryBase } from '@/content/questionnaireReportcontent.json'

import { RadarChart } from 'vue-chart-3'

export default {
  components: {
    RadarChart
  },
  data () {
    return { summaryBase }
  },
  props: {
    summaryData: {
      type: Object,
      required: true
    },
    dataset: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    content: {
      type: Object,
      required: true
    },
    candidate: {
      type: Object,
      required: true
    },
    printMode: {
      type: Boolean,
      required: true
    },
    isRegulationPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created () {
    // scores can go up to 10 on the last page
    if (this.content.title === 'Cognitive- and emotional regulation') {
      this.options.scales.r.max = 10
    }
    this.options.animation = this.pdf ? false : undefined
  },
  computed: {
    getNormValue () {
      return this.isRegulationPage ? 5 : 49.5
    }
  }
}
</script>

<style>

@media print and (min-width: 768px) {
  .bp1 {
    width: 40%;
    height: 80%;
  }
}
</style>
