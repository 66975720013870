<template>
  <div
    class="h-full grid grid-cols-3 grid-rows-3 content-center"
    :class="{
      'flex flex-col': !printMode,
      'flex flex-auto h-full w-full print-page-size': printMode
    }"
  >
    <div class="page-title">
      <h2>
        {{ content.startPage.title }}
      </h2>
    </div>
    <div class="grid grid-cols-9 grid-rows-3 col-span-3 row-start-2 content-center justify-items-center mt-8 mb-4">
      <select
        class="w-full select select-bordered text-lg col-span-3 col-start-4 row-start-2 h-20"
        v-model="reportCandidate"
      >
        <option
          disabled
          value="null"
          hidden
        >
          <p class="text-gray-300">
            Select candidate...
          </p>
        </option>
        <option
          v-for="candidate in candidates"
          :key="candidate.id"
          :value="candidate"
        >
          <!-- {{ candidate.user_alt_id }} -->
          Candidate {{ candidate.alt_id }}
          <span
            v-if="openedPersonalityReport(candidate)"
            class="text-base"
          >&nbsp;(opened)</span>
        </option>
      </select>
      <div
        v-if="reportCandidate"
        class="flex w-full items-center col-span-6 row-start-3 col-start-4 -ml-4"
      >
        <p class="text-lg font-normal">
          <span class="px-3">Email: {{ reportCandidate.email }} </span>
          <span v-if="reportCandidate.current_age">|</span>
          <span
            v-if="reportCandidate.current_age"
            class="px-3"
          >Age: {{ reportCandidate.current_age }} years
          </span>
          <span v-if="reportCandidate.gender">|</span>
          <span
            v-if="reportCandidate.gender"
            class="px-3"
          >Gender: {{ reportCandidate.gender }}</span>
        </p>
      </div>
      <div
        v-if="reportCandidate && !selectedCandidate"
        @click="openReport"
        class="btn btn-primary content-center text-center row-start-2 col-start-7 w-90 h-20 col-span-2"
      >
        <p class="w-full">
          Open report
        </p>
      </div>
    </div>
    <confirm-report-modal
      v-if="showConfirmModal"
      :report-type="personalityReportType"
      @close="showConfirmModal = false"
      @open-report="selectedCandidate = reportCandidate; showConfirmModal = false"
      :candidate="reportCandidate"
      :campaign-id="campaignId"
      :label="personalityLabel"
      :show-info="showInfo"
    />
  </div>
</template>

<script>
import * as content from '@/content/questionnaireReportcontent.json'
import ConfirmReportModal from '../../Scoreboard/components/ConfirmReportModal.vue'

export default {
  components: {
    ConfirmReportModal
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    candidates: {
      type: Array,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    },
    printMode: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    selectedCandidate (candidate) {
      if (candidate !== null) {
        // if we opened a new candidate or opened personality report for the first time
        if (candidate.stages == null) {
          this.selectedCandidate.stages = [this.personalityReportType]
        } else if (!candidate.stages.includes(this.personalityReportType)) {
          this.selectedCandidate.stages.push(this.personalityReportType)
        }
      }
      this.$emit('input', candidate)
    },
    reportCandidate (candidate) {
      if (candidate.stages !== null && candidate.stages.length > 0) {
        this.selectedCandidate = candidate
      } else {
        this.selectedCandidate = null
      }
    }
  },
  data () {
    return {
      selectedCandidate: this.value, // candidate whose report we will show
      reportCandidate: this.value, // candidate chosen in dropdown
      showConfirmModal: false,
      showInfo: true,
      personalityReportType: 'personality_report',
      personalityLabel: 'Personality++ report',
      content: content.default
    }
  },
  methods: {
    openReport () {
      this.showConfirmModal = true
    },
    openedPersonalityReport (candidate) {
      if (candidate.stages == null) {
        return false
      }
      return candidate.stages.includes(this.personalityReportType)
    }
  }
}
</script>
<style></style>
