<template>
  <div
    class="flex-col h-full 2xl:flex social-style"
    :class="{'flex-col h-full 2xl:flex social-style': !printMode, 'flex-col h-full 2xl:flex social-style print-page-size-top-margin': printMode}"
  >
    <div class="flex items-center justify-between">
      <div class="flex page-title">
        <h2>
          {{ content.title }}
        </h2>
        <img
          class="mx-2 mt-2 transition duration-150 ease-in-out img-fluid info-icon"
          src="@/assets/reports/personality/Circle_Info.svg"
          data-bs-toggle="tooltip"
          :title="content.desc"
        >
      </div>
      <div
        class="flex float-right"
        v-if="!printMode"
      >
        <img
          class="mx-1 img-fluid profile-icon"
          src="@/assets/reports/personality/User_cicrle_duotone_line.svg"
        >
        <p class="text-base font-medium">
          {{ candidate.name ? candidate.name : candidate.email }}
        </p>
      </div>
    </div>
    <div class="flex flex-col items-center mt-12">
      <div class="curvepage-title-width">
        <h5 class="mb-2 text-base font-bold text-center">
          {{ content.measures.main.label }}
        </h5>
        <p class="mt-1 mb-8 text-center">
          {{ dataset[content.measures.main.dataIndex] >= 5 ? content.measures.main.descHigher : content.measures.main.descLower }}
        </p>
      </div>
    </div>
    <div class="flex justify-evenly">
      <div class="flex flex-col items-center justify-center">
        <img
          v-if="content.measures.main.label !== 'Overall trait - Level of impulsivity and activity' "
          :src="loadImage(dataset[0])"
          width="450px"
        >
        <img
          v-if="content.measures.main.label === 'Overall trait - Level of impulsivity and activity'"
          :src="loadImage(dataset[0])"
          width="450px"
        >
        <div class="flex flex-row w-full mt-1">
          <div class="w-1/2">
            <p class="text-xs text-left">
              {{ content.measures.main.keywordsLower }}
            </p>
          </div>
          <div class="w-1/2">
            <p class="text-xs text-right">
              {{ content.measures.main.keywordsHigher }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center arrow-divider relative">
      <img
        src="@/assets/reports/personality/arrow.svg"
      >
      <span class="absolute top-20 text-xs">Sub-factors</span>
    </div>
    <div class="flex items-center justify-evenly">
      <div
        v-for="(subTraitContent, index) in Object.values(content.measures).slice(1)"
        :key="index"
        class="flex flex-col items-center w-4/12"
      >
        <h5 class="text-base font-bold text-center ">
          {{ subTraitContent.label }}
        </h5>
        <div class="h-28">
          <p class="px-4 pt-4 text-base text-center">
            {{ dataset[subTraitContent.dataIndex] >= 5 ? subTraitContent.descHigher : subTraitContent.descLower }}
          </p>
        </div>
        <img
          v-if="subTraitContent.label === 'Hyperactivity'"
          :src="loadImage(dataset[subTraitContent.dataIndex])"
          width="450px"
        >
        <img
          v-if="subTraitContent.label !== 'Hyperactivity'"
          :src="loadImage(dataset[subTraitContent.dataIndex])"
          width="450px"
        >
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    dataset: {
      type: Array,
      required: true
    },
    content: {
      type: Object,
      required: true
    },
    candidate: {
      type: Object,
      required: true
    },
    printMode: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    loadImage (score) {
      return require(`@/assets/reports/bellCurveSplits/${score}.svg`)
    },
    loadImageRev (score) {
      return require(`@/assets/reports/bellCurveSplits/r${score}.svg`)
    }
  }
}
</script>

<style scoped>

@media (min-width: 1536px) {
    .imgbp {
        width: 450px;
    }
}
.imgw {
    width: 400px;
}

</style>
