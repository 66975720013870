<template>
  <div class="flex flex-col w-full h-full">
    <div class="page-title">
      <h2>
        {{ content.title }}
      </h2>
    </div>
    <div class="flex h-full">
      <div class="w-1/2 h-full px-4">
        <div class="w-4/5">
          <div
            class="flex flex-col items-center justify-center"
            style="height: 500px"
          >
            <img
              src="@/assets/chartExamples/bell_curve_stanine.png"
              alt=""
              width="550"
            >
          </div>
          <h3 class="text-center text-2xl leading-9 font-medium">
            {{ content.subtitle1 }}
          </h3>
          <p class="text-center text-lg leading-snug mt-4">
            {{ content.desc1 }}
          </p>
        </div>
      </div>
      <div class="w-1/2 h-full px-4 flex justify-end">
        <div class="w-4/5">
          <div
            class="flex flex-col items-center justify-center"
          >
            <div style="height: 500px">
              <img
                src="@/assets/chartExamples/spider-chart-sample.svg"
                alt=""
                width="530"
              >
            </div>
            <h3 class="text-center text-2xl leading-9 font-medium">
              {{ content.subtitle2 }}
            </h3>
            <p class="text-center text-lg leading-snug mt-4">
              {{ content.desc2 }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { introPage3 } from '@/content/questionnaireReportcontent.json'

export default {
  data () {
    return {
      content: introPage3
    }
  }
}
</script>

<style>

</style>
