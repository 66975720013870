<template>
  <div
    class="flex flex-col items-center justify-center w-full h-full welcome-page-bg-color"
  >
    <img
      src="@/assets/capacio/report/welcome-design.svg"
      :class="{'z-10': pdf}"
      class="welcome-img"
    >
    <img
      src="@/assets/capacio/login/logo.svg"
      :class="{'z-10': pdf}"
      class="w-3/12"
    >
    <h1 class="text-white  normal-case text-3xl font-semibold mt-16">
      Personality++
    </h1>
    <h6
      v-if="showNoResultsMsg"
      class="text-white"
    >
      No tests have been completed, distribute your test <router-link
        :to="{name: 'Overview'}"
        class="link"
      >
        here
      </router-link>
    </h6>
    <h6
      v-else
      class="px-8  mt-16 text-lg font-normal leading-snug text-center text-white text-width-intro"
    >
      This self-rating questionnaire captures several aspects of personality “traits” as well as general strengths and weaknesses that relate to cognition.
    </h6>
  </div>
</template>

<script>
export default {
  props: {
    batteryId: {
      type: String,
      required: true
    },
    showNoResultsMsg: Boolean
  }
}
</script>

<style>

</style>
