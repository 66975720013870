<template>
  <div class="w-full h-full">
    <div class="page-title">
      <h2>
        {{ content.title }}
      </h2>
    </div>
    <p class="mt-6 text-lg leading-snug">
      {{ content.desc }}
    </p>
    <p class="mt-6 text-lg leading-snug">
      {{ content.desc1 }}
    </p>
    <p class="mt-6 text-lg leading-snug">
      {{ content.desc2 }}
    </p>
    <p class="mt-6 text-lg leading-snug">
      {{ content.desc3 }}
    </p>
    <br>
    <p class="mt-4 text-xs">
      References found on <a
        href="https://capaciotalent.com/"
        target="_blank"
        class="underline"
      > capaciotalent.com </a>
    </p>
    <div class="grid grid-cols-1 gap-8 mt-6">
      <div class="flex justify-center">
        <div
          v-for="(item, index) in content.contents.slice(0, 3)"
          :key="index"
          class="about-report-content-box h-16 min-h-full flex justify-center items-center mx-7"
        >
          <p class="text-base text-center text-white m-0">
            {{ item }}
          </p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-8 mt-6">
      <div class="flex justify-center mb-7">
        <div
          class="about-report-content-box h-16 min-h-full flex justify-center items-center mx-6"
          v-for="(item, index) in content.contents.slice(3, 5)"
          :key="index"
        >
          <p class="text-base text-center text-white m-0">
            {{ item }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { introPage1 } from '@/content/questionnaireReportcontent.json'

export default {
  data () {
    return {
      content: introPage1
    }
  }
}
</script>

<style>

</style>
