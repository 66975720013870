<template>
  <div
    class="flex justify-between px-12 py-6 bottom-menu-main"
    style=""
  >
    <playlist
      :popup-visible="popupVisible"
      @update:popupVisible="popupVisible = $event"
      :is-sum="false"
    />
    <playlist
      :popup-visible="popupSumVisible"
      @update:popupVisible="popupSumVisible = $event"
      :is-sum="true"
    />
    <div class="flex items-center w-1/3">
      <router-link :to="(personality) ? (($store.state.ANALYTICS.currentCampaign) ? {name: 'Analytics', params: {campaignId: $store.state.ANALYTICS.currentCampaign.id, page: $store.state.CAMPAIGNS.currentPage} } : '/analytics/0' ) : { name: 'Scoreboard', params: { campaignId, page: 0 } }">
        <div class="flex items-center text-white text-sm font-normal h-14">
          <svg
            width="28"
            height="17"
            viewBox="0 0 33 22"
            fill="none"
            class="mr-3"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.458 10.9454L1 10.9454"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M0.999842 10.9437L10.9453 20.8892"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M0.999841 10.9454L10.9453 0.999963"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Scoreboard
        </div>
      </router-link>
    </div>
    <div
      class="flex justify-center w-1/3"
      v-if="!personality || hasSelectedCandidate"
    >
      <button
        v-if="value != 0"
        :disabled="isDownloading"
        @click="$emit('input', value - 1)"
      >
        <svg
          width="34"
          height="34"
          viewBox="0 0 42 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.7735 4.81238H21.211C30.031 4.81238 37.171 11.9699 37.171 20.7724V21.2099C37.171 30.0299 30.0135 37.1699 21.211 37.1699H20.7735C11.9535 37.1699 4.81348 30.0124 4.81348 21.2099V20.7724C4.81348 11.9699 11.971 4.81238 20.7735 4.81238Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.4079 20.9999H15.6279"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.5947 20.9999L19.8997 25.3224"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.5947 21L19.8997 16.6775"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <button
        @click="$emit('input', 1)"
        class="mx-6"
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.8648 33.1876H12.1348V22.7751C12.1348 21.8126 12.9223 21.0251 13.8848 21.0251H20.1148C21.0773 21.0251 21.8648 21.8126 21.8648 22.7751V33.1876V33.1876Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M33.1875 15.9325V27.675C33.1875 30.72 30.8425 33.1875 27.9375 33.1875H6.0625C3.1575 33.1875 0.8125 30.72 0.8125 27.675V15.9325C0.8125 14.2875 1.495 12.7475 2.7025 11.6975L13.64 2.08996C15.5825 0.374961 18.4175 0.374961 20.36 2.08996L31.2975 11.6975C32.505 12.7475 33.1875 14.2875 33.1875 15.9325Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <button
        v-if="value != max - 1"
        :disabled="isDownloading"
        @click="$emit('input', value + 1)"
      >
        <svg
          width="34"
          height="34"
          viewBox="0 0 42 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.2265 3.50012H20.7715C11.2515 3.50012 3.49902 11.2526 3.49902 20.7726V21.2276C3.49902 30.7476 11.2515 38.5001 20.7715 38.5001H21.2265C30.7465 38.5001 38.499 30.7476 38.499 21.2276V20.7726C38.499 11.2526 30.7465 3.50012 21.2265 3.50012ZM27.334 21.9276L23.029 26.2501C22.7665 26.4951 22.434 26.6351 22.1015 26.6351C21.769 26.6351 21.419 26.4951 21.174 26.2501C20.649 25.7251 20.649 24.9026 21.174 24.3951L23.239 22.3126H15.5915C14.8565 22.3126 14.279 21.7176 14.279 21.0001C14.279 20.2826 14.8565 19.6876 15.5915 19.6876H23.239L21.174 17.6051C20.649 17.0976 20.649 16.2751 21.174 15.7501C21.6815 15.2426 22.504 15.2426 23.029 15.7501L27.334 20.0726C27.859 20.5801 27.859 21.4201 27.334 21.9276Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
    <div
      class="flex justify-end w-1/3"
      v-if="!personality || hasSelectedCandidate"
    >
      <div
        class="dropdown dropdown-top dropdown-end flex relative justify-center"
        v-if="!personality"
      >
        <div
          v-if="!hovered && (value==6 || value==7|| value==10)"
          @mouseover="hovered=true"
          class="absolute bottom-0 inline-block w-64 px-4 py-3 mb-10 -ml-32 text-center text-white bg-white rounded-lg info-tooltip"
          style="box-shadow: 6px 6px 10px rgb(0 0 0 / 0.5); margin-bottom: 40px; margin-left: 20px;"
        >
          <span class="inline-block text-sm leading-tight text-black">Learn more about the profile</span>
          <span
            class="absolute bottom-0 right-0 w-5 h-5 -mb-1 transform rotate-45 bg-white"
            style="left:50%;"
          />
        </div>
        <label
          @click="hovered=true"
          tabindex="0"
          class="flex items-center text-sm font-normal text-white cursor-pointer"
          style="white-space: nowrap;"
        > <svg
          class="mx-4"
          fill="#fff"
          width="30"
          height="30"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M492.373 20.482v76.401c0 11.311 9.169 20.48 20.48 20.48s20.48-9.169 20.48-20.48V20.482c0-11.311-9.169-20.48-20.48-20.48s-20.48 9.169-20.48 20.48zm-269.704 92.457l38.195 66.161c5.655 9.796 18.18 13.152 27.976 7.497s13.152-18.18 7.497-27.976L258.142 92.46c-5.655-9.796-18.18-13.152-27.976-7.497s-13.152 18.18-7.497 27.976zM61.441 310.666l66.161 38.195c9.796 5.655 22.321 2.299 27.976-7.497s2.299-22.321-7.497-27.976L81.92 275.193c-9.796-5.655-22.321-2.299-27.976 7.497s-2.299 22.321 7.497 27.976zm836.66 38.2l66.161-38.195c9.796-5.655 13.152-18.18 7.497-27.976s-18.18-13.152-27.976-7.497l-66.161 38.195c-9.796 5.655-13.152 18.18-7.497 27.976s18.18 13.152 27.976 7.497zM764.837 179.103l38.195-66.161c5.655-9.796 2.299-22.321-7.497-27.976s-22.321-2.299-27.976 7.497l-38.195 66.161c-5.655 9.796-2.299 22.321 7.497 27.976s22.321 2.299 27.976-7.497zM619.705 609.548a20.479 20.479 0 019.029-16.979c53.847-36.315 86.469-95.279 86.469-159.497 0-107.392-90.442-194.744-202.353-194.744s-202.353 87.353-202.353 194.744c0 64.218 32.623 123.181 86.469 159.497a20.48 20.48 0 019.029 16.979v181.903c0 22.143 17.946 40.09 40.09 40.09h133.54c22.134 0 40.079-17.947 40.079-40.09V609.548zm40.96 181.904c0 44.763-36.283 81.05-81.039 81.05h-133.54c-44.765 0-81.05-36.285-81.05-81.05V620.14c-59.555-44.178-95.498-112.657-95.498-187.067 0-130.338 109.092-235.704 243.313-235.704s243.313 105.366 243.313 235.704c0 74.41-35.943 142.89-95.498 187.067v171.312zM410.986 949.054h203.735c11.311 0 20.48-9.169 20.48-20.48s-9.169-20.48-20.48-20.48H410.986c-11.311 0-20.48 9.169-20.48 20.48s9.169 20.48 20.48 20.48zm25.467 76.4h152.801c11.311 0 20.48-9.169 20.48-20.48s-9.169-20.48-20.48-20.48H436.453c-11.311 0-20.48 9.169-20.48 20.48s9.169 20.48 20.48 20.48z" /></svg>

          Understand report</label>
        <ul
          tabindex="0"
          class="p-4 overflow-visible border border-gray-300 shadow dropdown-content menu bg-base-100 rounded-box w-96"
        >
          <li
            class="text-sm leading-snug text-left"
          >
            <a
              @click="toggleSumPopup"
              class="relative flex items-center cursor-pointer hover:text-gray-600"
            >
              <div
                class="relative flex inline-flex items-center tooltip tooltip-right"
                :data-tip="'a personalized playlist for interpreting the profile'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  />
                </svg>

                Result, short video

              </div>
            </a>
          </li>
          <li
            class="text-sm leading-snug text-left"
          >
            <a
              @click="togglePopup"
              class="relative flex items-center cursor-pointer hover:text-gray-600"
            >
              <div
                class="relative flex items-center text-left tooltip tooltip-right"
                :data-tip="'an extended customized playlist for interpretation and recommendation'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-8 h-8 mr-3"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0118 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0118 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 016 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5"
                  />
                </svg>

                Result/recommendations, long video

              </div>
            </a>
          </li>
          <li
            class="text-sm leading-snug text-left"
          >
            <a
              href="https://demo.capaciotalent.com/docs/"
              target="_blank"
              class="relative flex items-center cursor-pointer hover:text-gray-600"
            >
              <div
                class="relative flex items-center text-left tooltip tooltip-right"
                :data-tip="'an extended customized playlist for interpretation and recommendation'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
                  />
                </svg>

                Full tutorial, video & text

              </div>
            </a>
          </li>
          <li
            class="text-sm leading-snug text-left"
          >
            <a
              href="https://youtu.be/Qmkf-5w4YB4"
              target="_blank"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mr-2"
            >
              <path
                stroke-linecap="round"
                d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
              />
            </svg>
              Report demo video</a>
          </li>
        </ul>
      </div>
      <label
        v-if="!fromOtp"
        @click="openChatbot()"
        class="flex items-center mx-3 text-sm font-medium text-white"
        style="white-space: nowrap;"
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 44 44"
          fill="none"
          class="mx-4"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4655 23.8881C15.5084 23.8881 16.3538 23.0427 16.3538 21.9998C16.3538 20.9569 15.5084 20.1115 14.4655 20.1115C13.4226 20.1115 12.5771 20.9569 12.5771 21.9998C12.5771 23.0427 13.4226 23.8881 14.4655 23.8881Z"
            fill="white"
          />
          <path
            d="M22.0006 23.8881C23.0435 23.8881 23.889 23.0427 23.889 21.9998C23.889 20.9569 23.0435 20.1115 22.0006 20.1115C20.9577 20.1115 20.1123 20.9569 20.1123 21.9998C20.1123 23.0427 20.9577 23.8881 22.0006 23.8881Z"
            fill="white"
          />
          <path
            d="M29.5358 23.8881C30.5787 23.8881 31.4241 23.0427 31.4241 21.9998C31.4241 20.9569 30.5787 20.1115 29.5358 20.1115C28.4929 20.1115 27.6475 20.9569 27.6475 21.9998C27.6475 23.0427 28.4929 23.8881 29.5358 23.8881Z"
            fill="white"
          />
          <path
            d="M37.4746 28.9665H37.4563C38.4096 26.8398 38.9596 24.4748 38.9596 21.9998C38.9596 12.6315 31.3696 5.0415 22.0013 5.0415C12.633 5.0415 5.04297 12.6315 5.04297 21.9998C5.04297 31.3682 12.633 38.9582 22.0013 38.9582C24.7696 38.9582 27.3913 38.2798 29.7013 37.1065L38.868 38.4632L37.4746 28.9665Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Chat with us
      </label>
      <div
        class="flex items-center"
      >
        <div class="dropdown dropdown-top dropdown-end">
          <label
            tabindex="0"
            class="cursor-pointer text-white flex items-center text-sm font-normal"
          >
            <svg
              width="20"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              stroke-width="1.2"
              class="mx-3"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
              />
            </svg>
            More</label>
          <ul
            tabindex="0"
            class="p-4 border border-gray-300 shadow dropdown-content menu bg-base-100 rounded-box w-96"
          >
            <li
              @click="$emit('download', false)"
              class="text-sm leading-snug text-left"
            >
              <a><svg
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="mr-2 w-7 h-7"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
                Download report</a>
            </li>
            <li
              @click="$emit('download', true)"
              class="text-sm leading-snug text-left"
            >
              <a><div>
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="mr-2 w-7 h-7"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </div>
                Download report (anonymized)</a>
            </li> <li
              v-if="!fromOtp"
              @click="$emit('share')"
              class="text-sm text-left leading-snug"
            >
              <a><div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="mr-2 w-7 h-7"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </div>
                Share with email</a>
            </li>
            <!-- If report is accessed through OTP (e.g with a shared link), we substitute link generation & mail with link copy. -->
            <li
              v-else
              @click="copyCurrentUrl()"
              class="text-sm text-left leading-snug"
            >
              <a><div>
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor"
                     viewBox="0 0 24 24"
                     stroke-width="1"
                     stroke="none"
                     class="mr-2 w-7 h-7"
                   >
                     <path
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       :d="linkCopyState.isCopied ? linkCopyState.iconPaths.copied : linkCopyState.iconPaths.copy"
                     />
                   </svg>
                 </div>
                {{ linkCopyState.isCopied ? linkCopyState.text.copied : linkCopyState.text.copy }}</a>
            </li>
            <li
              v-if="allowRawDataReport"
              @click="$emit('downloadRawDataReport')"
            >
              <a><div>
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="mr-2 w-7 h-7"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </div>
                Download raw data report (ADMIN)</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Playlist from '@/components/Playlist.vue'

export default {
  watch: {
    value (newValue) {
      this.hovered = false
    }
  },
  components: {
    Playlist
  },
  methods: {
    openChatbot () {
      this.$crisp.chat.show()
      this.$crisp.chat.open()
      this.$crisp.message.send('text', 'Hello I need help!')
    },
    togglePopup () {
      this.popupVisible = !this.popupVisible
    },
    toggleSumPopup () {
      this.popupSumVisible = !this.popupSumVisible
    },
    copyCurrentUrl () {
      const currentUrl = window.location.href

      navigator.clipboard.writeText(currentUrl)
        .then(() => {
          this.linkCopyState.isCopied = true

          setTimeout(() => {
            this.linkCopyState.isCopied = false
          }, 2000)
        })
        .catch(() => {
          console.error('Unable to copy to clipboard')
        })
    }
  },
  props: {
    value: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    },
    isDownloading: {
      type: Boolean
    },
    fromOtp: {
      type: Boolean,
      required: false,
      default: false
    },
    allowRawDataReport: {
      type: Boolean,
      required: false,
      default: false
    },
    personality: {
      type: Boolean,
      required: false,
      default: false
    },
    hasSelectedCandidate: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      popupVisible: false,
      popupSumVisible: false,
      hovered: false,
      linkCopyState: {
        isCopied: false,
        iconPaths: {
          copied: 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z',
          copy: 'M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z'
        },
        text: {
          copied: 'Copied!',
          copy: 'Copy link'
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "../bottommenu.scss";
</style>
