<template>
  <div
    :class="{'flex flex-col h-full': !printMode, 'flex flex-col w-full h-full print-page-size-top-margin': printMode}"
  >
    <div class="page-title">
      <h2>
        {{ content.selectCandidate.title }}
      </h2>
    </div>
    <!-- <div class="grid grid-cols-3 gap-4 mt-8 mb-4">
      <select
        class="w-full select select-bordered text-lg"
        v-model="selectedCandidate"
      >
        <option
          v-for="candidate in candidates"
          :key="candidate.id"
          :value="candidate"
        >
          {{ candidate.user_alt_id }}
        </option>
      </select>
      <div
        v-if="value"
        class="flex items-center col-span-2"
      >
        <p class="text-base font-normal">
          <span class="px-3">Email: {{ value.email }} </span> <span v-if="value.current_age">|</span> <span
            v-if="value.current_age"
            class="px-3"
          >Age: {{ value.current_age }} years </span> <span v-if="value.gender">|</span> <span
            v-if="value.gender"
            class="px-3"
          >Gender: {{ value.gender }}</span>
        </p>
      </div>
    </div> -->
    <table class="mt-8 text-left table-auto table-executive-summary border-spacing-y-52">
      <thead>
        <tr>
          <th class="width-60" />
          <th class="py-4 pl-6 text-base font-semibold table-cell-green table-border-bottom-green table-border-right-green table-border-left-green width-30">
            {{ content.selectCandidate.highestTrait }}
          </th>
          <th class="py-4 pl-6 text-base font-semibold table-cell-yellow table-border-right-yellow table-border-bottom-yellow width-30">
            {{ content.selectCandidate.lowestTrait }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="border border-l-0 border-slate-400">
          <td class="p-5 table-border-right-green">
            <p class="text-lg font-semibold leading-7">
              {{ content.bfi10.title }}
            </p>
          </td>
          <td
            class="p-5 align-top table-border-right-green"
          >
            <div
              v-for="bfi10Max in summaryData.bfi10.max"
              :key="bfi10Max"
            >
              <p class="text-base font-medium">
                {{ content.bfi10.measures[bfi10Max].label }}
              </p><p class="mb-4 text-base font-normal content-color leading-snug">
                {{ content.bfi10.measures[bfi10Max].descHigher }}
              </p>
            </div>
          </td>
          <td
            class="p-5 align-top table-border-right-yellow"
          >
            <div
              v-for="bfi10Min in summaryData.bfi10.min"
              :key="bfi10Min"
            >
              <p class="text-base font-medium">
                {{ content.bfi10.measures[bfi10Min].label }}
              </p><p class="mb-4 text-base font-normal content-color leading-snug">
                {{ content.bfi10.measures[bfi10Min].descLower }}
              </p>
            </div>
          </td>
        </tr>
        <tr class="border border-l-0 border-slate-400">
          <td class="p-5 table-border-right-green">
            <p class="text-lg font-semibold leading-7">
              {{ content.iri.title }}
            </p>
          </td>
          <td class="p-5 align-top table-border-right-green">
            <div
              v-for="iriMax in summaryData.iri.max"
              :key="iriMax"
            >
              <p class="text-base font-medium">
                {{ content.iri.measures[iriMax].label }}
              </p><p class="mb-4 text-base font-normal content-color leading-snug">
                {{ content.iri.measures[iriMax].descHigher }}
              </p>
            </div>
          </td>
          <td class="p-5 align-top table-border-right-yellow">
            <div
              v-for="iriMin in summaryData.iri.min"
              :key="iriMin"
            >
              <p class="text-base font-medium">
                {{ content.iri.measures[iriMin].label }}
              </p><p class="mb-4 text-base font-normal content-color leading-snug">
                {{ content.iri.measures[iriMin].descLower }}
              </p>
            </div>
          </td>
        </tr>
        <tr class="border border-l-0 border-slate-400">
          <td class="p-5 table-border-right-green">
            <p class="text-lg font-semibold leading-7">
              {{ content.aesc.title }}
            </p>
          </td>
          <td class="p-5 align-top table-border-right-green">
            <div
              v-for="aescMax in summaryData.aesc.max"
              :key="aescMax"
            >
              <p class="text-base font-medium">
                {{ content.aesc.measures[aescMax].label }}
              </p><p class="mb-4 text-base font-normal content-color leading-snug">
                {{ content.aesc.measures[aescMax].descHigher }}
              </p>
            </div>
          </td>
          <td class="p-5 align-top table-border-right-yellow">
            <div
              v-for="aescMin in summaryData.aesc.min"
              :key="aescMin"
            >
              <p class="text-base font-medium">
                {{ content.aesc.measures[aescMin].label }}
              </p><p class="mb-4 text-base font-normal content-color leading-snug">
                {{ content.aesc.measures[aescMin].descLower }}
              </p>
            </div>
          </td>
        </tr>
        <!-- <p
          v-if="printMode"
          class="mt-1 mb-1 text-base font-normal"
        >
          (page 1/2)
        </p> -->
        <tr
          :class="{'border border-l-0 border-r-0 border-slate-400': !printMode, 'border border-t-0 border-l-0 border-r-0 border-slate-400': printMode}"
        >
          <td class="p-5">
            <p class="text-lg font-semibold leading-7">
              {{ content.asrs.title }}
            </p>
            <img
              class="my-4 img-fluid table-img"
              :src="loadImage(summaryData.asrs.score)"
            >
          </td>
          <td
            class="p-5"
            colspan="2"
          >
            <p class="mt-6 text-base font-medium">
              {{ content.asrs.measures.main.label }}
            </p>
            <p class="pr-4 mt-1 mb-4 text-base font-normal content-color leading-snug">
              {{ content.asrs.measures.main.descHigher }}
              {{ content.asrs.measures.main.descLower }}
            </p>
          </td>
        </tr>
        <tr
          class="border border-l-0 border-r-0 border-slate-400"
        >
          <td class="p-5">
            <p class="text-lg font-semibold leading-7">
              {{ content.aq.title }}
            </p>
            <img
              class="my-4 img-fluid table-img"
              :src="loadImage(summaryData.aq.score)"
            >
          </td>
          <td
            class="p-5"
            colspan="2"
          >
            <p class="mt-6 text-base font-medium">
              {{ content.aq.measures.main.label }}
            </p>

            <p class="pr-4 mt-1 mb-4 text-base font-normal content-color leading-snug">
              {{ content.aq.measures.main.descHigher }}
              {{ content.aq.measures.main.descLower }}
            </p>
          </td>
        </tr>
        <!-- <p
          v-if="printMode"
          class="mt-1 mb-1 text-base font-normal"
        >
          (page 2/2)
        </p> -->
      </tbody>
    </table>
  </div>
</template>

<script>

import * as content from '@/content/questionnaireReportcontent.json'

export default {

  props: {
    value: {
      type: Object,
      required: true
    },
    candidates: {
      type: Array,
      required: true
    },
    summaryData: {
      type: Object,
      required: true
    },
    printMode: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    selectedCandidate (candidate) {
      this.$emit('input', candidate)
    }
  },
  methods: {
    loadImage (score) {
      return require(`@/assets/reports/bellCurveSplits/${score}.svg`)
    },
    loadImageReverse (score) {
      return require(`@/assets/reports/bellCurveSplits/r${score}.svg`)
    }
  },

  data () {
    return {
      selectedCandidate: this.value,
      content: content.default
    }
  }
}
</script>
<style>

</style>
